import React from 'react'

export const Miasta = function(props) {
  const { miasta, sort, priceType } = props
  return (
    <span style={{ marginTop: 20, display: 'block' }}>
      {miasta
        .sort((miastoA, miastoB) => {
          if (sort === 'PRICE') {
            if (miastoA[priceType] < miastoB[priceType]) return -1
            if (miastoA[priceType] > miastoB[priceType]) return 1
            return 0
          } else if (sort === 'NAME') {
            if (miastoA.name < miastoB.name) return -1
            if (miastoA.name > miastoB.name) return 1
            return 0
          }
          return 0
        })
        .map(miasto => {
          const size = miasto.population > 100000 ? 15 : 14
          const weight = miasto.population > 100000 ? 'bold' : 'normal'
          return (
            <span
              style={{ fontSize: `${size}px`, fontWeight: weight }}
              className="miastoCennik"
              key={miasto.name}
            >
              {miasto.name} - {miasto[priceType]}
              zł
            </span>
          )
        })}
    </span>
  )
}
