import React from 'react'
import { ArrowLeft } from './ArrowLeft'

export const BackToHome = () => {
  return (
    <div className="row section">
      <div className="col-12">
        <div style={{ justifyContent: 'center', display: 'flex' }}>
          <div className="content-wide">
            <div className="link-home-wrapper">
              <a className="link-home" href={'/'}>
                <div>
                  <ArrowLeft size={36} /> Strona główna
                </div>
                <div className="link-home-sub">
                  Na stronie głównej znajdziesz więcej informacji o mnie / pełną
                  ofertę oraz opinie
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
