import React from 'react'


import endoskopia1 from '../img/endoskopia-1.jpg'
import endoskopia2 from '../img/endoskopia-2.jpg'
import endoskopia3 from '../img/endoskopia-3.jpg'
import endoskopia4 from '../img/endoskopia-4.jpg'


import endoskopia1webp from '../img/endoskopia-1.webp'
import endoskopia2webp from '../img/endoskopia-2.webp'
import endoskopia3webp from '../img/endoskopia-3.webp'
import endoskopia4webp from '../img/endoskopia-4.webp'

function GalleryEndoskopia() {
  return (
    <div id="section-gallery" className="row section">
      <div className="col-12 section-content">
        <div className={'gallery'} style={{ justifyContent: 'center' }}>

          <picture>
            <source srcSet={endoskopia1webp} type="image/webp" />
            <source srcSet={endoskopia1} type="image/jpeg" />
            <img
              title="Endoskopia silnika 1"
              alt="Endoskopia silnika 1"
              src={endoskopia1}
            />
          </picture>

          <picture>
            <source srcSet={endoskopia2webp} type="image/webp" />
            <source srcSet={endoskopia2} type="image/jpeg" />
            <img
              title="Endoskopia silnika 2"
              alt="Endoskopia silnika 2"
              src={endoskopia2}
            />
          </picture>


          <picture>
            <source srcSet={endoskopia3webp} type="image/webp" />
            <source srcSet={endoskopia3} type="image/jpeg" />
            <img
              title="Endoskopia silnika 3"
              alt="Endoskopia silnika 3"
              src={endoskopia3}
            />
          </picture>


          <picture>
            <source srcSet={endoskopia4webp} type="image/webp" />
            <source srcSet={endoskopia4} type="image/jpeg" />
            <img
              title="Endoskopia silnika 4"
              alt="Endoskopia silnika 4"
              src={endoskopia4}
            />
          </picture>




        </div>
      </div>
    </div>
  )
}

export default GalleryEndoskopia
