import React from 'react'

import Sedan from '../components/sedan'
import HalfImg from '../components/HalfImg'
import Gallery from '../components/GalleryEndoskopia'
import { miasta as miastaDetailed } from '../utils/data-detailed'
import { KILOMETROWKA, BASE_PRICE_ENDOSKOPIA } from '../utils/cena'
import ContactTopBar from '../components/ContactTopBar'
import Layout from '../components/Layout'
import { Miasta } from '../components/Miasta'
import { BackToHome } from '../components/BackToHome'

class IndexPage extends React.Component {
  constructor() {
    super()
    this.state = { allCitiesVisible: false }
    this.displayAllCities = this.displayAllCities.bind(this)
  }

  componentDidMount() {
    // gatsby own scroll mess with this
    if (typeof window !== 'undefined') {
      if (window.scrollY) {
        window.scroll(0, 0)
      } else if (window.scrollTo) {
        window.scrollTo(0, 0)
      }
    }
  }

  displayAllCities = () => {
    this.setState({ allCitiesVisible: !this.state.allCitiesVisible })
  }

  render() {
    return (
      <Layout>
        <div>
          <div className="row img-section" id={'main'}>
            <div className="overlay-effect" />

            <div className="col-12 img-section-content">
              <ContactTopBar />
              <div style={{ justifyContent: 'center' }}>
                <a href="/">
                  <Sedan size={64} />
                </a>
                <h1>Auto Test 360 - Marian Gibała</h1>
                <h2>Endoskopia Silnika - Śląsk</h2>
                <h3 id="miasta">
                  <span>Inspekcja</span>
                  <span>Diagnostyka komputerowa</span>
                  <span>Kontrola techniczna auta</span>
                  <span>Endoskopia silnika</span>
                </h3>
              </div>

              <div className="content">
                <p>
                  Endoskopia to najlepszy sposób oceny stanu komory spalania,
                  tulei cylindrów oraz zaworów. Metoda pozwala określić stopień
                  zużycia oraz zakres uszkodzeń przed zakupem silnika używanego
                  lub po awarii rozrządu bez demontażu głowicy.
                </p>
              </div>
            </div>
          </div>

          <HalfImg imgId="img-5">
            <h3>Co to jest endoskopia silnika?</h3>
            <p>
              Za pomocą endoskopu po wykręceniu świecy zapłonowej lub demontażu
              wtryskiwaczy możemy zajrzeć do wnętrza silnika bez demontażu
              głowicy, dzięki temu jesteśmy w stanie wstępnie ocenić stan tłoka,
              ścianek cylindra, zaworów bądź gniazd zaworowych.
            </p>
            <p>
              Diagnostyka endoskopowa pozwala zaoszczędzić czas i koszty
              związane z rozbiórką silnika, oraz daje możliwości oceny i
              weryfikacji stanu poszczególnych obszarów.
            </p>
            <p>
              Bardzo ważne przy ocenie stanu silnika jest doświadczenie, wiedza,
              oraz jakość samego endoskopu, którego głowica musi mieć możliwość
              obrotu o 180 stopni.
            </p>

            <p>
              Sprawdzenie komory spalania pozwala zweryfikować stan:
              <li>Tulei cylindra</li>
              <li>Zaworów</li>
              <li>Uszkodzeń głowicy</li>
              <li>Spalania oleju</li>
              <li>Przebiegu w przypadku wątpliwości</li>
            </p>

            <p>
              Endoskopia silnika będzie pomocna w następujących przypadkach:
              <li>Weryfikacja przy zakupie silnika używanego</li>
              <li>Weryfikacja po zerwaniu rozrządu</li>
              <li>Weryfikacja po naprawach</li>
              <li>W poszukiwaniu usterek</li>
            </p>

            <p>
              Do przeprowadzenia endoskopii niezbędne jest zapewnienie dostępu
              do cylindrów. W silnikach benzynowych poprzez wykręcenie świec, a
              w silnikach diesla wtryskiwaczy.
            </p>
          </HalfImg>

          <HalfImg imgId="img-6" imgRight={true}>
            <h3>Oferta - Endoskopia Silnika</h3>

            <div className="process-item">
              <div className="process-num">
                <span>1</span>
              </div>
              <p>
                Skontaktuj się ze mną w celu ustalenia detali oraz terminu
                inspekcji. Oględzin przeprowadzam w dowolnym wybranym przez
                klienta miejscu lub warsztacie. Po ustaleniu detali wpłać
                ustaloną kwotę na podane poniżej konto.
                <br />
                <br />
                <span className="bold">
                  Jaki jest koszt endoskopii silnika?
                </span>{' '}
                <br />
                <br />
                <span className="cena"> {BASE_PRICE_ENDOSKOPIA}zł</span> +
                koszty dojazdu ({KILOMETROWKA}zł za każdy kilometr od Gliwic,
                przejazd przez bramki na autostradzie).
                <br />
                <br />
                <span>Przybliżony cennik, inspekcja silnika + dojazd:</span>
                <Miasta
                  priceType="endoskopiaPrice"
                  miasta={miastaDetailed.filter(m => m.displayInPricing)}
                  sort="PRICE"
                />
                <br />
                <span
                  role="button"
                  style={{ cursor: 'pointer' }}
                  onClick={this.displayAllCities}
                >
                  Zobacz pozostałe miasta ->
                </span>
                {this.state.allCitiesVisible && (
                  <Miasta
                    priceType="endoskopiaPrice"
                    sort="NAME"
                    miasta={miastaDetailed.filter(m => !m.displayInPricing)}
                  />
                )}
              </p>
            </div>
          </HalfImg>

          <Gallery />
          <BackToHome />
        </div>
      </Layout>
    )
  }
}

export default IndexPage
